// custom typefaces
import "@fontsource-variable/signika"
import "@fontsource/libre-baskerville"
import  "@fontsource-variable/nunito-sans"
import "@fontsource/pacifico"

// normalize CSS across browsers
import "./src/normalize.css"
// custom CSS styles
import "./src/style.css"
import "flag-icons/css/flag-icons.min.css"

// Highlighting for code blocks
import "prismjs/themes/prism.css"

//gatsby-browser.js
export const onClientEntry = () => {
    if (!("ScrollTimeline" in window)) {
      const script = document.createElement("script");
      script.src = "https://flackr.github.io/scroll-timeline/dist/scroll-timeline.js";
      script.type = "application/javascript";
      script.onload = () => console.log("ScrollTimeline polyfill loaded.");
      script.onerror = (e) => console.error("Failed to load ScrollTimeline polyfill:", e);
      document.body.appendChild(script);
    }
  };
  